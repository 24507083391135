<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center py-3">
        <h3 class="mb-0 font-size-h5 font-weight-bolder">Select warehouse</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-250px scroll-y mx-5">
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.warehouse_id"
            :items="warehouses"
            item-text="text"
            item-value="index"
            label="Type for searching warehouses"
            dense
            outlined
            clearable
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            @change="onChange"
          />
        </div>

        <!--begin::Actions-->
        <div class="mb-2 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";

export default {
  name: "AddWarehouseModal",
  props: ["ruleParams", "onSubmit"],
  data: () => ({
    dialog: false,
    lastElementIndex: null,
    entries: [],
    search: null,
    isLoading: false,
    forId: true,
    formData: {
      warehouse_id: null,
      name: null,
    },
  }),
  methods: {
    onChange() {
      this.formData.name = this.warehouses.find(
        (item) => item.index === this.formData.warehouse_id
      ).text;
    },
    submitEditForm() {
      this.onSubmit(this.forId, this.formData.warehouse_id, this.formData.name);
      this.toggleModal();
    },
    resetFormData() {
      this.formData = {
        warehouse_id: null,
        name: null,
      };
      this.entries = [];
      this.search = null;
      this.isLoading = false;
    },
    toggleModal(forId) {
      if (this.dialog) this.resetFormData();
      else {
        this.forId = forId;
      }
      this.dialog = !this.dialog;
    },
    // initData(index, newItem) {
    //   this.lastElementIndex = index;
    //   this.getData(newItem.systemCode);
    //   this.formData = newItem;
    //   this.dialog = true;
    // },
    async getData(value) {
      const requestBody = { q: value };

      await fetch(
        `${process.env.VUE_APP_BASE_URL}/${this.ruleParams.search_url}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(requestBody),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.warehouses) {
            // this.entries = res.skus.map((item) => ({
            //   index: item.skuId,
            //   text: item.systemCode,
            // }));
            this.entries = [...res.warehouses];
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    warehouses: function () {
      return this.entries;
    },
  },
  watch: {
    search(val) {
      if (val) {
        this.isLoading = true;
        this.getData(val);
      }
    },
  },
};
</script>
