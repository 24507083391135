<template>
  <div class="d-flex">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col cols="6" class="pr-3">
          <v-select
            label="Attribute"
            v-model="rule.id"
            :items="options"
            item-text="text"
            item-value="index"
            dense
            outlined
          />
        </b-col>
        <b-col cols="6" class="pr-3" v-if="selectedOption.type === 'dropdown'">
          <v-select
            v-model="rule.value"
            :label="opLabel"
            :multiple="Boolean(multipleForWarehouse)"
            :items="ruleOperatorOptions"
            item-text="text"
            item-value="index"
            dense
            outlined
            :error-messages="valueErrors"
            @blur="$v.rule.value.$touch()"
          />
        </b-col>
        <b-col cols="6" class="pr-3" v-if="selectedOption.type === 'text'">
          <v-text-field
            v-model="rule.value"
            :label="opLabel"
            dense
            outlined
            :error-messages="valueErrors"
            @blur="$v.rule.value.$touch()"
          />
        </b-col>
      </b-row>
    </b-container>
    <div class="max-h-65">
      <button
        class="h-40px w-40px btn btn-sm btn-icon btn-light-info btn-hover-light"
        @click="deleteRule"
      >
        <span class="svg-icon">
          <v-icon size="18">mdi-delete</v-icon>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { SET_ACTION_NOT_VALID } from "@/core/services/store/warehouseAllocationRules.module";

export default {
  name: "Action",
  mixins: [validationMixin],
  validations: {
    rule: {
      value: { required },
    },
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
    },
    userId: {
      required: false,
    },
    operators: {
      required: true,
      type: Object,
    },
  },
  computed: {
    selectedOption() {
      return this.options.find((option) => option.index === this.rule.id);
    },
    currentRuleID: function () {
      return this.rule.id;
    },
    ruleOperatorOptions: function () {
      return this.operators[
        `${
          this.options.find((option) => option.index === this.rule.id).key_name
        }`
      ];
    },
    opLabel() {
      return this.options.find((option) => option.index === this.rule.id).text;
    },
    multipleForWarehouse() {
      return this.options.find((option) => option.key_name === "warehouse");
    },
    valueErrors: function () {
      return this.handleFormValidation("value", this, "rule");
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },

    deleteRule: function () {
      const self = this;
      const parentRules = self.$parent.currentQuery;
      parentRules.splice(parentRules.indexOf(self.rule), 1);
    },
  },
  watch: {
    currentRuleID: function () {
      this.rule.value = null;
    },
    rule: {
      handler() {
        // console.log("rule", this.rule);
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit(SET_ACTION_NOT_VALID, true);
        } else {
          this.$store.commit(SET_ACTION_NOT_VALID, false);
        }
      },
      deep: true,
    },
  },
};
</script>
